import textFit from 'textfit';

// Team page functionality
// ---------------------------------------------------------------------------------------------------------------------
function toggleExtraBioVisibility(evnt) {
    let pressedButton = evnt.currentTarget;
    let expansion_text_div;

    for (let ele of document.getElementsByClassName('bio_text_expansion')) {
        if (ele.id === pressedButton.id) {
            expansion_text_div = ele;
        }
    }

    let style = getComputedStyle(expansion_text_div);
    if (style.display === 'none') {
        expansion_text_div.style.display = 'block';
        pressedButton.style.transform = 'scale(1, 1)';
        pressedButton.firstElementChild.style.transform = 'scale(1, 1)';
        pressedButton.firstElementChild.innerText = "- less information";

    }
    else {
        expansion_text_div.style.display = 'none';
        pressedButton.style.transform = 'scale(1, -1)';
        pressedButton.firstElementChild.style.transform = 'scale(1, -1)';
        pressedButton.firstElementChild.innerText = "+ more information";
    }
}

// console.log('main.js loaded');
for (let ele of document.getElementsByClassName('bio_expand_button')) {
    ele.addEventListener('click', toggleExtraBioVisibility);
}
// ---------------------------------------------------------------------------------------------------------------------


// Contact page functionality
// ---------------------------------------------------------------------------------------------------------------------
let recaptchaDiv = null;
let scale = 1.0;

function ScaleReCaptcha()
{
    let vw = window.innerWidth;
    if (vw > 430 && vw < 700) {
        // scale = Math.min(vw / 730, 1);
        scale = Math.min(vw / 566, 1);
    }
    else {
        scale = Math.min(vw / 430, 1);
    }
    recaptchaDiv.style.transform = 'scale(' + scale + ')';
}

window.addEventListener('load', function() {
    recaptchaDiv = document.getElementById('recaptcha_wrapper');
    if (recaptchaDiv !== null) {
        ScaleReCaptcha();
        window.addEventListener('resize', () => {
            ScaleReCaptcha();
        });
    }
});
// ---------------------------------------------------------------------------------------------------------------------


// Portfolio page functionality
// ---------------------------------------------------------------------------------------------------------------------
let aspectRatioMode = 'landscape';
let portFolioDiv = null;
let projectName = '';
let projectNavigatorDiv = null;
let navigationCircles = [];
let projectTitleBarDiv = null;
let projectTitleDiv = null;
let initialLogoSettings = {};
let projectLogoDiv = null;
let projectLinkDiv = null;
let projectTextDiv = null;
let projectDivs = [];
let allProjectLogos = [];
let leftButtons = null;
let rightButtons = null;
let arrowAngle = 35; // [deg]
let arrowWidth = 15; // [%]

let currentProjectIndex = 0;
let maxProjectIndex = 0;
let projectTextDivHeight = 0;
let projectTextHeight = 0;

let startTime = null;
let startX = null;
let startY = null;
let oldX = null;
let oldY = null;

window.addEventListener('load', function() {
    portFolioDiv = document.getElementById('portfolio_container');
    if (portFolioDiv !== null) {
        projectNavigatorDiv = document.getElementById('project_navigation');
        projectTitleBarDiv = document.getElementById('project_title_bar');
        projectTitleDiv = document.getElementById('project_title');
        projectLogoDiv = document.getElementById('project_logo');
        projectLinkDiv = document.getElementById('project_link');
        projectTextDiv = document.getElementById('project_text');
        projectDivs = document.getElementsByClassName('project');
        allProjectLogos = document.getElementsByClassName('project_logo');
        maxProjectIndex = projectDivs.length;

        leftButtons = document.getElementsByClassName('left_button');
        if (projectDivs.length > 0) {
            for (let leftButton of leftButtons) {
                leftButton.addEventListener('click', scrollPortfolioLeft);
            }
            rightButtons = document.getElementsByClassName('right_button');
            for (let rightButton of rightButtons) {
                rightButton.addEventListener('click', scrollPortfolioRight);
            }
        }

        window.matchMedia("(max-aspect-ratio: 29/20)").addEventListener('change', toggleAspectRatioMode);
        toggleAspectRatioMode(window.matchMedia("(max-aspect-ratio: 29/20)"));

        window.addEventListener('resize', () => {
            resizeButtons();
            resizeProjectTitleBar();
        });

        createProjectNavigator();
        resetProjectScrollPosition();

        portFolioDiv.addEventListener('wheel', interpretScrollEvent, {passive: true});
        portFolioDiv.addEventListener('mousedown', function(event) {
            startX = event.pageX;
            startY = event.pageY;
            startTime = new Date().getTime();
            portFolioDiv.addEventListener('mousemove', interpretScrollEvent, {passive: true});
        }, {passive: true});

        portFolioDiv.addEventListener('mouseup', function(event) {
            portFolioDiv.removeEventListener('mousemove', interpretScrollEvent);
            detectAndHandleSwipe(event);
            oldX = null;
            oldY = null;
        }, {passive: true});
        portFolioDiv.addEventListener("touchstart", touchHandler, {passive: true});
        portFolioDiv.addEventListener("touchmove", touchHandler, {passive: true});
        portFolioDiv.addEventListener("touchend", touchHandler, {passive: true});
        portFolioDiv.addEventListener("touchcancel", touchHandler, {passive: true});

        updateProjectView();
        // resizeButtons();
    }
});

function tempLog(logText) {
    document.getElementById('slogan').innerText = `${logText}`;
}

function createProjectNavigator() {
    let c0 = 0;
    for (let project of projectDivs) {
        let circle = document.createElement('div');
        circle.classList.add('project_navigation_circle');
        projectNavigatorDiv.appendChild(circle);
        circle.addEventListener('click', goToProject.bind(null, c0));
        navigationCircles.push(circle);
        if (c0 > 0) {
            circle.style.backgroundColor = 'white';
        }
        c0 += 1;
    }
}

function toggleAspectRatioMode(x, evnt) {
    if (x.matches) {
        aspectRatioMode = 'portrait';
        projectTextDiv.style.marginTop = '';
    }
    else {
        aspectRatioMode = 'landscape';
    }
    window.dispatchEvent(new Event('resize')); // Unfortunate addition to force a redraw since stupid Apple stuff messes up the lay-out after an orientation switch. This is fixed with a redraw.
}

function resizeButtons() {
    // console.log('ResizeButtons');
    let polygonLeft = '';
    let polygonRight = '';

    switch (aspectRatioMode) {
        case 'landscape':
            let widthHeightRatio = 0.5 * portFolioDiv.offsetWidth / portFolioDiv.offsetHeight;
            // polygonLeft = `polygon(0 100%, ${arrowWidth}% 100%, ${100 * Math.tan(arrowAngle / 180 * Math.PI) / widthHeightRatio + arrowWidth}% 0, ${100 * Math.tan(arrowAngle / 180 * Math.PI) / widthHeightRatio}% 0)`;
            // polygonRight = `polygon(100% 0, ${100 - arrowWidth}% 0, ${100 - arrowWidth - 100 * Math.tan(arrowAngle / 180 * Math.PI) / widthHeightRatio}% 100%, ${100 - 100 * Math.tan(arrowAngle / 180 * Math.PI) / widthHeightRatio}% 100%)`;
            polygonLeft = `polygon(0 100%, ${arrowWidth}% 100%, ${100 * Math.tan(arrowAngle / 180 * Math.PI) / widthHeightRatio + arrowWidth}% 0, 0 0)`;
            polygonRight = `polygon(100% 0, ${100 - arrowWidth}% 0, ${100 - arrowWidth - 100 * Math.tan(arrowAngle / 180 * Math.PI) / widthHeightRatio}% 100%, 100% 100%)`;
            break;
        case 'portrait': {
            return;
        }
    }
    let leftButton = document.getElementById('left_button');
    let rightButton = document.getElementById('right_button');

    leftButton.style['shape-outside'] = polygonLeft;
    leftButton.style['-webkit-shape-outside'] = polygonLeft;
    leftButton.style['clip-path'] = polygonLeft;
    rightButton.style['shape-outside'] = polygonRight;
    rightButton.style['-webkit-shape-outside'] = polygonRight;
    rightButton.style['clip-path'] = polygonRight;
}

function resizeProjectTitleBar() {
    // console.log('resizeProjectTitlebar');
    let titleBarDiv = document.getElementById('project_title_bar');
    let titleSubBarDiv = document.getElementById('project_title_sub_bar');

    let titleBarWidth = 0;
    let titleBarHeight = 0;
    let titleBarMarginLeft = 0;
    let titleBarMarginTop = 0;

    let titleSubBarHeight = 0;
    let titleSubBarWidth = 0;
    let titleSubBarMargin = 0;
    let titleSubBarPadding = 0;

    let navigationBarHeight = projectNavigatorDiv.offsetHeight;
    let navigationBarWidth = 0;
    let navigationBarMarginLeft = 0;

    let containerWidth = portFolioDiv.offsetWidth;
    let containerHeight = portFolioDiv.offsetHeight;
    let arrowWidthPx = arrowWidth / 100 * containerWidth / 2;

    switch (aspectRatioMode) {
        case 'landscape':
            let titleBarRatio = 9; // 9

            titleSubBarWidth = titleBarRatio * (containerWidth - 2 * arrowWidthPx - containerHeight * Math.tan(arrowAngle/180 * Math.PI)) / (titleBarRatio + Math.tan(arrowAngle/180 * Math.PI));
            titleSubBarHeight = titleSubBarWidth / titleBarRatio;
            titleSubBarPadding = titleSubBarHeight *  Math.tan(arrowAngle/180 * Math.PI) + convertRemToPixels(1); // Need to make wider on the left and right so scrolling text will be hidden uunderneath
            titleBarWidth = titleSubBarWidth  + 2 * titleSubBarPadding;
            titleBarHeight = titleSubBarHeight + convertRemToPixels(1);
            titleBarMarginLeft = arrowWidthPx + containerHeight * Math.tan(arrowAngle/180 * Math.PI) - titleSubBarPadding;
            titleSubBarMargin = titleSubBarPadding;
            titleBarMarginTop = -5;

            titleBarDiv.style.width = `${titleBarWidth}px`;
            titleBarDiv.style.height = `${titleBarHeight}px`;
            titleSubBarDiv.style.width = `${titleSubBarWidth}px`;
            titleSubBarDiv.style.height = `${titleSubBarHeight}px`;

            navigationBarWidth = containerWidth - 2 * arrowWidthPx - (containerHeight - 2 * navigationBarHeight) * Math.tan(arrowAngle/180 * Math.PI);
            navigationBarMarginLeft = arrowWidthPx;
            break;
        case 'portrait': {
            titleBarDiv.style.width = '100%';
            titleBarDiv.style.height = '100%';
            titleSubBarDiv.style.width = '100%';
            titleSubBarDiv.style.height = '100%';

            titleBarWidth = titleBarDiv.offsetWidth;
            titleBarHeight = titleBarDiv.offsetHeight;
            titleSubBarWidth = titleBarWidth;
            titleSubBarHeight = titleBarHeight;

            navigationBarWidth = titleBarWidth;
            break;
        }
    }

    titleBarDiv.style.marginLeft = `${titleBarMarginLeft}px`;
    titleBarDiv.style.marginTop = `${titleBarMarginTop}px`;
    titleSubBarDiv.style.marginLeft = `${titleSubBarMargin}px`;
    titleSubBarDiv.style.marginTop = `${-titleBarMarginTop}px`;

    // Navigation bar resizing and repositioning
    projectNavigatorDiv.style.width = `${navigationBarWidth}px`;
    projectNavigatorDiv.style.left = `${navigationBarMarginLeft}px`;

    // Initial logo placement
    projectLogoDiv.style.top = '0px';
    projectLogoDiv.style.right = `${titleSubBarPadding}px`;

    // Fit text to div and align
    textFit(projectTitleDiv, {alignVert: true, multiLine: true, reProcess: true, minFontSize: 15});

    // Shrink logo if it is too tall. Otherwise, vertically center
    if (projectLogoDiv.offsetHeight > titleSubBarHeight) {
        projectLogoDiv.style.width = `${titleSubBarHeight * initialLogoSettings[projectName]['logoRatio']}px`;
    }
    else {
        projectLogoDiv.style.top = `${(titleSubBarHeight - projectLogoDiv.offsetHeight) / 2}px`;
    }

    // Perform some horizontal centering on the logo too
    let remainingSpace = titleSubBarWidth - projectTitleDiv.offsetWidth - projectLogoDiv.offsetWidth;
    projectLogoDiv.style.right = `${titleSubBarPadding + remainingSpace / 2}px`;
}

function goToProject(index) {
    unsetStyles();
    navigationCircles[currentProjectIndex].style.backgroundColor = 'white';
    currentProjectIndex = index;
    navigationCircles[currentProjectIndex].style.backgroundColor = '';
    updateProjectView();
}

function scrollPortfolioLeft() {
    unsetStyles();
    navigationCircles[currentProjectIndex].style.backgroundColor = 'white';
    currentProjectIndex -= 1;
    if (currentProjectIndex === -1) {
        currentProjectIndex = maxProjectIndex - 1;
    }
    navigationCircles[currentProjectIndex].style.backgroundColor = '';
    updateProjectView();
}

function scrollPortfolioRight() {
    unsetStyles();
    navigationCircles[currentProjectIndex].style.backgroundColor = 'white';
    currentProjectIndex += 1;
    if (currentProjectIndex === maxProjectIndex) {
        currentProjectIndex = 0;
    }
    navigationCircles[currentProjectIndex].style.backgroundColor = '';
    updateProjectView();
}

function unsetStyles() {
    projectTitleDiv.classList = '';
    projectLogoDiv.classList = '';
    projectTextDiv.classList = '';
    projectLogoDiv.style.width = '';
    projectLogoDiv.style.right = '';
}

function updateProjectView() {
    let projectDiv = projectDivs[currentProjectIndex];
    projectName = projectDiv.childNodes[0].nodeValue.trim();
    // console.log(`Showing project: ${projectName}`);

    let projectTitle = projectDiv.getElementsByClassName('project_title')[0].innerHTML;
    let projectLink = projectDiv.getElementsByClassName('project_link')[0].getElementsByTagName('a')[0];
    let projectLogo = projectDiv.getElementsByClassName('project_logo')[0].getElementsByTagName('img')[0];
    let projectText = projectDiv.getElementsByClassName('project_text')[0].innerHTML;

    if (!initialLogoSettings[projectName]) {
        initialLogoSettings[projectName] = {
            'logoWidth': projectLogo.naturalWidth,
            'logoHeight': projectLogo.naturalHeight,
            'logoRatio': projectLogo.naturalWidth / projectLogo.naturalHeight,
            'divWidthPercentage': projectDiv.getElementsByClassName('project_logo')[0].style.width
        };
    }

    let img = new Image();
    img.src = projectLogo.currentSrc;
    img.alt = projectLogo.alt;
    img.id = projectLogo.id;

    projectTitleDiv.innerHTML = projectTitle;
    projectLinkDiv.innerHTML = '';
    projectLinkDiv.setAttribute('href', projectLink.href);
    projectLinkDiv.appendChild(projectLogo);
    projectDiv.getElementsByClassName('project_logo')[0].appendChild(img);
    projectTextDiv.innerHTML = projectText;

    projectTitleDiv.classList.add(`${projectName}_title`);
    projectLogoDiv.classList.add(`${projectName}_logo`);
    projectTextDiv.classList.add(`${projectName}_text`);

    resetProjectScrollPosition();
    resizeProjectTitleBar();
    resizeButtons();
}

function touchHandler(event)
{
    let touches = event.changedTouches,
        first = touches[0],
        type = "";

    switch(event.type)
    {
        case "touchstart": type = "mousedown"; break;
        case "touchmove":  type = "mousemove"; break;
        case "touchend":   type = "mouseup";   break;
        default:           return;
    }

    // Emit a mouse event corresponding to the touch event
    let simulatedEvent = document.createEvent("MouseEvent");
    simulatedEvent.initMouseEvent(type, true, true, window, 1,
        first.screenX, first.screenY,
        first.clientX, first.clientY, false,
        false, false, false, 0/*left*/, null);

    first.target.dispatchEvent(simulatedEvent);
    // event.preventDefault();
}

function interpretScrollEvent(evnt) {
    switch (evnt.type) {
        case 'wheel':
            let scrollLines = 0;
            switch (evnt.deltaMode) {
                case 0: // in pixel units
                    scrollLines = evnt.deltaY / 50;
                    break;
                case 1: // in line units
                    scrollLines = evnt.deltaY
                    break;
                case 2: // in page units
                    scrollLines = evnt.deltaY * 10
                    break;
                default:
                    console.log(`Wheel event could not be interpreted. Unknown deltaMode: ${evnt.deltaMode}`)
            }
            verticalScrollProject(scrollLines);
            break;
        case 'mousemove':
            if (oldX !== null) {
                let directionY = (oldY - evnt.pageY) / 10;
                throttleFunction(verticalScrollProject.bind(null, directionY), 50);
            }
            oldX = evnt.pageX;
            oldY = evnt.pageY;
            break;
        default:
            return;
    }
}

function detectAndHandleSwipe(evnt) {
    let threshold = 75; //required min distance traveled to be considered swipe
    let restraint = 50; // maximum distance allowed at the same time in perpendicular direction
    let allowedTime = 500; // maximum time allowed to travel that distance

    let distX = evnt.pageX - startX // get horizontal dist traveled by finger while in contact with surface
    let distY = evnt.pageY - startY // get vertical dist traveled by finger while in contact with surface
    let elapsedTime = new Date().getTime() - startTime // get time elapsed

    let swipeDirection = 'none';

    if (elapsedTime <= allowedTime){ // first condition for swipe met
        if (Math.abs(distX) >= threshold && Math.abs(distY) <= restraint){ // 2nd condition for horizontal swipe met
            swipeDirection = (distX < 0)? 'left' : 'right' // if dist traveled is negative, it indicates left swipe
        }
        else if (Math.abs(distY) >= threshold && Math.abs(distX) <= restraint){ // 2nd condition for vertical swipe met
            swipeDirection = (distY < 0)? 'up' : 'down' // if dist traveled is negative, it indicates up swipe
        }
    }

    switch (swipeDirection) {
        case 'left':
            scrollPortfolioRight();
            break;
        case 'right':
            scrollPortfolioLeft();
            break;
        default:
            return;
    }
}

function resetProjectScrollPosition() {
    switch (aspectRatioMode) {
        case 'landscape':
            projectTextDiv.style.marginTop = '0rem';
            break;
        case 'portrait':
            projectTextDiv.style.marginTop = '';
            break;
    }
}

function verticalScrollProject(scrollValue) {
    switch (aspectRatioMode) {
        case 'landscape':
            let currentMarginValue = projectTextDiv.style.marginTop.match(/[+-]?\d+(?:\.\d+)?/g);
            let currentMarginUnit = projectTextDiv.style.marginTop.split(/[+-]?\d+(?:\.\d+)?/g);
            // currentMarginUnit = currentMarginUnit[currentMarginUnit.length - 1];
            currentMarginUnit = 'rem';
            let newMarginValue = Math.min(Math.round(10 * (currentMarginValue - scrollValue)) / 10, 0);

            projectTextHeight = projectTextDiv.clientHeight;
            projectTextDivHeight = portFolioDiv.offsetHeight - projectLogoDiv.offsetHeight - projectTitleDiv.offsetHeight;
            if ((scrollValue > 0 && (projectTextDivHeight - convertRemToPixels(newMarginValue) < projectTextHeight + convertRemToPixels(4))) || (scrollValue < 0)) {
                projectTextDiv.style.marginTop = newMarginValue + currentMarginUnit;
                window.dispatchEvent(new Event('resize')); // Unfortunate addition to force a redraw since stupid Apple stuff messes up the text after a scroll. This is fixed with a redraw.
            }
            break;
        case 'portrait':
            return
    }
}

function convertRemToPixels(rem) {
    return rem * parseFloat(getComputedStyle(document.documentElement).fontSize);
}

// Throttle function: Input as function which needs to be throttled and delay is the time interval in milliseconds
let timerId;
let throttleFunction  =  function (func, delay) {
    // If setTimeout is already scheduled, no need to do anything
    if (timerId) {
        return
    }

    // Schedule a setTimeout after delay seconds
    timerId  =  setTimeout(function () {
        func()

        // Once setTimeout function execution is finished, timerId = undefined so that in <br>
        // the next scroll event function execution can be scheduled by the setTimeout
        timerId  =  undefined;
    }, delay)
}
